import React from "react";
import {Typography, TypographyProps} from "@mui/material"

export default function SectionTitle(props: TypographyProps) {
  return (
    <Typography
      variant="h1"
      color="primary"
      textAlign="center"
      margin="2rem 0"
      {...props}
    />
  );
}