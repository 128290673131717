import React from "react";
import {Container, styled, Typography} from "@mui/material";
import {Helmet} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";

import SiteLayout from "components/layouts/SiteLayout";
import SectionTitle from "components/atoms/SectionTitle";
import Link from "components/atoms/Link";
import {ROUTES_PL} from "config";

const Text = styled(Typography)`
  margin-bottom: 1rem;

  ul {
    margin-block: 0;
    margin-inline: 0;
    padding-inline-start: 40px;
  }
`;

export default function TestsPrepare() {
  return (
    <SiteLayout>
      <Helmet>
        <title>DokDok - Jak przygotować się do badań</title>
      </Helmet>
      <Container>
        <SectionTitle id="lab">Jak przygotować się do badań laboratoryjnych?</SectionTitle>
        <Typography variant="h2">Badania wymagające szczególnego przygotowania - test obciążenia glukozą (OGTT) i test obciążenia insuliną:</Typography>
        <Text>
          Przed badaniem należy zakupić 75 gram glukozy w aptece. Zabierz ze sobą również wodę niegazowaną min. 500 ml,
          przed wyjściem do przychodni wypij co najmniej 2 szklanki wody. Badanie krzywej cukrowej wymaga pozostania
          w przychodni przez 2 godziny - uwzględnij to w planie dnia. Nie będzie możliwe opuszczenie przychodni w tym czasie,
          ponieważ wpływa to na zafałszowanie wyniku.
        </Text>
        <Typography variant="h3">Badania wykonywane w punkcie pobrań DokDok:</Typography>
        <Text>Prosimy o zgłoszenie się do punktu pobrań jak najbliżej godziny 8:00.</Text>
        <Typography variant="h3">Badania wykonywane w punkcie Synevo</Typography>
        <Text>Jeśli chcesz wykonać badania w punkcie naszego partnera, należy wcześniej dokonać rezerwacji terminu przez infolinię +48 221 20 24 00 lub stronę <a href="https://www.synevo.pl/rezerwacja-krzywa" target="_blank">www.</a></Text>
        <Typography variant="h2">Badania wymagające pobrania krwi żylnej:</Typography>
        <Text>
          Zaleca się pobierać materiał rano, na czczo - po całonocnym wypoczynku, po 12-14
          godzinach od spożycia ostatniego posiłku.
          Przed badaniem wypij co najmniej 2 szklanki wody. Ogranicz wysiłek fizyczny na minimum
          12 godzin przed pobraniem krwi.
        </Text>
        <Typography variant="h2">Badania moczu:</Typography>
        <Text>
          Zaleca się pobranie porannej porcji moczu, ze środkowego strumienia po uprzednim
          podmyciu się (pierwszą porcję oddaje się do ubikacji, drugą do pojemnika); pojemnik nie
          musi być jałowy, ważne by był zakupiony w aptece. Objętość ok. 10-20 ml (1/3 pojemnika
          wystarczy).
        </Text>
        <Text>
          Do posiewu moczu prosimy o oddanie próbki do jałowego (sterylnego) pojemnika.
          Pobrany mocz należy przechowywać w warunkach chłodniczych.
          Materiał prosimy dostarczyć w godzinach pracy punktu pobrań (8:00-10:00)
        </Text>
        <Typography variant="h2">Badania kału:</Typography>
        <Text>
          Prosimy o dostarczenie próbki w dedykowanym pojemniku zakupionym w aptece, w ilości
          nie większej jak 1/3 pojemnika. Przed dostarczeniem próbki należy ją przechowywać w
          warunkach chłodniczych.
        </Text>
        <Typography variant="h2">Wymazy z gardła, nosa:</Typography>
        <Text>
          Zaleca się bycie na czczo lub minimum 2-3 godziny od ostatniego posiłku, bez mycia zębów.
          Jeśli wymaz będzie pobierany z nosa należy delikatnie go wydmuchać.
        </Text>
        <Typography variant="h2">Badania wymagające szczególnego przygotowania - test obciążenia glukozą (OGTT):</Typography>
        <Text>
          Prosimy o zgłoszenie się do punktu pobrań jak najbliżej godziny 8:00. Przed badaniem
          należy zakupić 75 gram glukozy w aptece. Zabierz ze sobą również wodę niegazowaną min.
          500 ml, przed wyjściem do przychodni wypij co najmniej 2 szklanki wody.
          Badanie krzywej cukrowej wymaga pozostania w przychodni przez 2 godziny - uwzględnij to
          w planie dnia. Nie będzie możliwe opuszczenie przychodni w tym czasie, ponieważ wpływa
          to na zafałszowanie wyniku.
        </Text>

        <Typography variant="h2">Poinformuj pielęgniarkę, jeśli:</Typography>
        <Text>
          <ul>
            <li>jesteś w ciąży</li>
            <li>przyjmujesz leki przeciwzakrzepowe (np. Acenocumarol)</li>
            <li>występują u Ciebie trudności w pobraniu krwi</li>
            <li>stresujesz się pobraniem - postaramy się pomóc :)</li>
          </ul>
        </Text>

        <Typography variant="h2">Inne badania specjalistyczne: </Typography>
        <Text>
          Informację o dostępnych badaniach i przygotowaniu
          uzyskasz od pielęgniarki kontaktując się mailowo <a href="mailto:dok@dokdok.pl">dok@dokdok.pl</a> lub dzwoniąc
          pod numer
          <a href="tel:506973850">&nbsp;506&nbsp;973&nbsp;850</a>.<br/>
          Cennik podstawowych badań laboratoryjnych znajduje się pod adresem:&nbsp;
          <a href="https://dokdok.pl/cennik-badan.pdf" target="_blank">https://dokdok.pl/cennik-badan.pdf</a>
        </Text>
        <Text>
          Do punktu pobrań w przychodni DokDok nie obowiązuje wcześniejsza rejestracja.
          Lista punktów pobrań dostępna <Link to={ROUTES_PL.directions} color="primary">tutaj</Link>.
        </Text>
      </Container>
      <Container>
        <SectionTitle id="usg">Jak przygotować się do badania USG?</SectionTitle>
        <Typography variant="h2">Ważne! Tylko prawidłowe przygotowanie do badania USG umożliwia dokładną ocenę badanych narządów.</Typography>

        <Typography variant="h2">Ultrasonografia jamy brzusznej</Typography>
        <Text>
          <ol>
            <li>Na badanie zgłoś się na czczo - w dniu badania nie jedz, nie pij, nie pal papierosów i jeśli to możliwe nie zażywaj leków.</li>
            <li>Unikaj na dzień przed badaniem jedzenia produktów powodujących powstawanie nadmiernej ilości gazów (np. napoje gazowane, surowe warzywa i owoce, ciemne pieczywo) i stosuj dietę lekkostrawną.</li>
            <li>Dzień przed badaniem przyjmuj 3x dziennie po 2 kapsułki Espumisanu i 2 kapsułki rano w dniu badania.</li>
          </ol>
        </Text>
        <Typography variant="h2">Ultrasonografia narządu rodnego, pęcherza moczowego, prostaty, płodu:</Typography>
        <Text sx={{ pb: {xs: 2, md: 4, lg: 6} }}>
          <ol>
            <li>Na badanie zgłoś się na czczo.</li>
            <li>Wpij 1 litr niegazowanych płynów (najlepiej wody niegazowanej) i zgłoś się na badanie z pełnym pęcherzem.</li>
            <li>Dzień przed badaniem przyjmuj 3x dziennie po 2 kapsułki Espumisanu i 2 kapsułki rano w dniu badania.</li>
          </ol>
        </Text>
      </Container>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
